import classNames from 'classnames'
import { Component } from "react";
import React from "react";
import { isMobile } from "../../shared"

import styles from './styles.module.css'

class Help extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile() })
    window.addEventListener('resize', this.updateViewport)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateViewport)
  }

  updateViewport = () => {
    const mobile = isMobile()
    if (this.state.isMobile !== mobile) {
      this.setState({ isMobile: mobile })
    }
    if (!mobile && this.state.fullscreen) {
      this.setState({ fullscreen: false })
    }
  }

  render() {
    const content = this.props.content
    return (
      <div className={styles.help} >
        <div className={styles.helpNow}>
          <div className={styles.textSection}
            data-aos="fade-up"
            data-aos-duration="1200">
            <div className={styles.titleSection}>
              {!this.state.isMobile ? (
                <div className={styles.title}> {content.title.value.toUpperCase()} </div>
              ) : (null)
              }
            </div>
            <div className={styles.lineSection}>
              {!this.state.isMobile ? (
                <div className={styles.line} />
              ) : (null)
              }
            </div>
            <div className={styles.bodySection}>
              <div className={styles.body}> {content.subtitle.value} </div>
            </div>
            <div className={styles.lineSection}>
              {!this.state.isMobile ? (
                <div className={styles.line} />
              ) : (null)
              }
            </div>
            <div className={classNames(styles.footerSection, styles.callToAction)}>
              <div className={styles.footer}>
                <a href="tel:911" className={styles.callToAction}> {content.callToAction.value.toUpperCase()} </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.resources}>
          <div className={styles.textSection}
            data-aos="fade-up"
            data-aos-duration="1200">
            <div className={styles.titleSection} />
            <div className={styles.lineSection} />
            <div className={styles.bodySection}>
              <div className={styles.reversedBody}> {content.resourceSubtitle.value} </div>
            </div>
            <div className={styles.lineSection}>
              {!this.state.isMobile ? (
                <div className={styles.reversedLine} />
              ) : (null)
              }
            </div>
            <div className={styles.footerSection}>
              <div className={styles.resourcesSection}>
                {content.resources.value.map((resource, index) => {
                  return <div className={styles.phoneNumberSection}>
                    <div key={index} className={styles.reversedTitle}
                      dangerouslySetInnerHTML={{
                        __html: resource.value.title.value.childMarkdownRemark.html,
                      }}
                    />
                    <a key={index} href={"tel:" + resource.value.phoneNumber.value} className={styles.phoneNumber}>{resource.value.phoneNumber.value}</a>
                    {resource.value.extra &&
                      <div key={index} className={styles.extra}
                        dangerouslySetInnerHTML={{
                          __html: resource.value.extra.value.childMarkdownRemark.html,
                        }}
                      />
                    }
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Help