import AOS from 'aos'
import { graphql } from 'gatsby'
import Help from '../components/Help';
import Layout from '../components/Layout';
import React, { PureComponent } from "react";
import withLoading from '../hocs/withLoading'

import styles from './styles.module.css'

class HelpPage extends PureComponent {
  componentDidMount = () => {
    AOS.init({
      duration: 700,
      offset: 120,
    })
  }

  render() {
    const { pageContext, data, loading } = this.props

    return (
      <Layout pageContext={pageContext} loading={loading} location={this.props.location}>
        <div className={styles.background}>
          <Help content={data.cockpitHelp} />
        </div>
      </Layout>
    )
  }
}

export default withLoading(HelpPage)

export const query = graphql`
{
  cockpitHelp (
    cockpitId: { eq: "5d39c4ce74d1a2008f00daf3" }
  ){
    title {
      value
    }
    subtitle {
      value
    }
    callToAction { 
      value
    }
    resourceSubtitle {
      value
    }
    resources {
      value {
        value {
          title {
            value {
              childMarkdownRemark {
                html
              }
            }
          }
          phoneNumber {
            value
          }
          extra {
            value {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
}
`
